import React from "react";
import "../styles/Checkbox.scss";

export default function Checkbox({ label, desc, className, ...props }) {
  return (
    <div className={className}>
      <label className="checkbox-synxus">
        <input type="checkbox" {...props} />
        <span className="checkmark"></span>
        <div className="block text-sm font-medium text-white">{label}</div>
      </label>
      {desc ? (
        <div className="block text-sm font-normal text-sGray-light pl-7">
          {desc}
        </div>
      ) : null}
    </div>
  );
}
