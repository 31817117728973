import React from "react";
import PropTypes from "prop-types";

export default function Input({
  label,
  className,
  placeholder,
  password,
  icon,
  ...props
}) {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <div className={`${className || ""} w-full`}>
      <label
        htmlFor="first_name"
        className="block text-sm font-medium text-white"
      >
        {label}
      </label>
      <div className="relative text-gray-600 focus-within:text-gray-400">
        {icon ? (
          <span
            className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <img src={icon} alt="logo" />
          </span>
        ) : null}

        {password ? (
          <input
            type={showPassword ? "text" : "password"}
            {...props}
            name="q"
            className={`text-gray-500 bg-transparent px-3 mt-1 focus:ring-indigo-500 focus:border-sGray-border focus-visible:border-sGray-border block w-full shadow-sm sm:text-sm border-sGray-border h-9 border rounded-md ${
              icon ? "pr-10" : ""
            }`}
            placeholder={placeholder}
            autoComplete="off"
          />
        ) : (
          <input
            {...props}
            name="q"
            className={`text-gray-500 bg-transparent px-3 mt-1 focus:ring-indigo-500 focus:border-sGray-border focus-visible:border-sGray-border block w-full shadow-sm sm:text-sm border-sGray-border h-9 border rounded-md ${
              icon ? "pr-10" : ""
            }`}
            placeholder={placeholder}
            autoComplete="off"
          />
        )}
      </div>
    </div>
  );
}
Input.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};
