import React from "react";

export default function TimePicker({ className, label, onChangeTime }) {
  const [ampm, setAmpm] = React.useState("am");
  const [hours, setHours] = React.useState("01");
  const [minutes, setMinutes] = React.useState("00");

  return (
    <div className={` w-full ${className}`}>
      <label
        htmlFor="project-desc"
        className="block text-sm font-medium text-white mt-5"
      >
        {label}
      </label>
      <div className="text-gray-500 bg-transparent mt-1 focus:ring-indigo-500 focus:border-sGray-border focus-visible:border-sGray-border block w-full shadow-sm sm:text-sm border-sGray-border h-9 border rounded-md">
        <div className="flex items-center h-full px-3">
          <select
            name="hours"
            className="bg-transparent text-sm appearance-none outline-none"
            onChange={(event) => {
              if (ampm === "am") {
                onChangeTime(event.target.value + ":" + minutes);
              } else {
                onChangeTime(parseInt(event.target.value) + 12 + ":" + minutes);
              }
              setHours(event.target.value);
            }}
          >
            <option value="01">01</option>
            <option value="02">02</option>
            <option value="03">03</option>
            <option value="04">04</option>
            <option value="05">05</option>
            <option value="06">06</option>
            <option value="07">07</option>
            <option value="08">08</option>
            <option value="09">09</option>
            <option value="10">10</option>
            <option value="11">10</option>
            <option value="12">12</option>
          </select>
          <span className="text-sm mr-1">:</span>
          <select
            name="minutes"
            className="bg-transparent text-sm appearance-none outline-none mr-1"
            onChange={(event) => {
              if (ampm === "am") {
                onChangeTime(hours + ":" + event.target.value);
              } else {
                onChangeTime(parseInt(hours) + 12 + ":" + event.target.value);
              }
              setMinutes(event.target.value);
            }}
          >
            <option value="00">00</option>
            <option value="30">30</option>
          </select>
          <select
            name="ampm"
            className="bg-transparent text-sm appearance-none outline-none"
            onChange={(event) => {
              if (event.target.value === "am") {
                onChangeTime(hours + ":" + minutes);
              } else {
                onChangeTime(parseInt(hours) + 12 + ":" + minutes);
              }
              setAmpm(event.target.value);
            }}
          >
            <option value="am">AM</option>
            <option value="pm">PM</option>
          </select>
        </div>
      </div>
    </div>
  );
}
