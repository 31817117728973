import React from "react";
import { useHistory } from "react-router-dom";
import { Input, Button, Checkbox } from "../components";
import { login, getUserById } from "../utils/api";
import { UserDispatchContext } from "../contexts/userContext";

import { useCookies } from "react-cookie";
export default function Login() {
  const [cookie, setCookie] = useCookies(["access_token", "_idUser"]);
  let history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const UserDispatch = React.useContext(UserDispatchContext);
  const onSubmit = () => {
    login({ email, password }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        const accessToken = data.accessToken;
        const idUser = data.id;

        setCookie("access_token", accessToken);
        setCookie("_idUser", data.id);

        getUserById({
          token: accessToken,
          id: idUser,
        }).then(async (r) => {
          if (r.ok) {
            const { data } = await r.json();

            UserDispatch({ type: "set-user", payload: data });
          } else {
            console.log("getUserById error");
          }
        });
        history.push("dashboard/calendar");
      } else {
        console.log("login error");
      }
    });
  };

  return (
    <div className="h-screen flex">
      <div className="md:w-1/3 w-full  bg-sBlack h-full flex flex-col items-center justify-center  md:px-20 px-9 ">
        <img src="/assets/Logo.svg" alt="logo" />
        <Input
          label="Email"
          className="mt-24"
          placeholder="you@example.com"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          label="Password"
          password
          className="my-5"
          icon="/assets/icon/eye.svg"
          placeholder="your Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="flex items-center justify-between w-full mb-5">
          <Checkbox label="Remember me" />
          <a
            href="/"
            className="text-sm font-medium text-sGreen hover:text-indigo-500"
          >
            Forgot your password?
          </a>
        </div>
        <Button
          className="w-full"
          onClick={
            () => onSubmit()
            //
          }
        >
          Sign in
        </Button>
      </div>
      <div
        className="w-2/3 hidden md:block h-full"
        style={{
          backgroundImage: "url(/assets/img/bg-login.png)",
          backgroundColor: "rgba(0, 0, 0, 0.87)",
          backgroundBlendMode: "multiply",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
    </div>
  );
}
