import React from "react";

const initialState = {
  user: {},
};

export const UserStateContext = React.createContext(initialState);
export const UserDispatchContext = React.createContext((it) => {});

function reducer(state, action) {
  switch (action.type) {
    case "set-user": {
      return {
        ...state,
        user: action.payload,
      };
    }
    default:
      throw new Error("Bad Action Type");
  }
}

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
};

export default UserContextProvider;
