import React from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  Drawer,
  Input,
  Button,
  Dropdown,
  TimePicker,
  Toast,
} from "../../components";
import "../../styles/Calendar.scss";
import "react-datepicker/dist/react-datepicker.css";
import { getAllTools, getBookingsByDate, postBooking } from "../../utils/api";
import getCookie from "../../utils/getcookie";
import {
  UserStateContext,
} from "../../contexts/userContext";
import { useCookies } from "react-cookie";

const localizer = momentLocalizer(moment);
const EventTest = ({ ...props }) => {
  return (
    <div>
      <div className="flex">
        <img src="/assets/icon/teams.svg" alt="teams" />
        <div className="ml-1 text-sm font-medium text-white">{props.title}</div>
      </div>
      <div className="text-xs text-gray-400 font-light mt-2">
        {props.event.desc && props.event.desc}
      </div>
    </div>
  );
};

const MyAgendaEvent = ({ ...props }) => {
  return <div className="bg-red-700">tef</div>;
};
const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div
    onClick={onClick}
    ref={ref}
    className="cursor-pointer rounded-lg bg-sBlack-txt p-0  text-white  h-10 flex items-center justify-center md:mr-9 w-10 md:w-auto"
  >
    <span className="md:px-4 hidden md:block">Select a date</span>
    <img
      src="/assets/icon/calendar.svg"
      alt="Previous day"
      className="md:border-l h-full  p-2 border-sGray-border"
    />
  </div>
));
const ExampleCustomHeader = ({ ...props }) => {
  return (
    <div className="flex items-center justify-between text-sm font-medium text-white mb-7">
      <div onClick={props.decreaseMonth} className="cursor-pointer">
        <img
          src="/assets/icon/back-arrow.svg"
          alt="Previous day"
          className="mr-4"
        />
      </div>
      {moment(props.date).format("MMMM - YYYY")}
      <div onClick={props.increaseMonth} className="cursor-pointer">
        <img
          src="/assets/icon/next-arrow.svg"
          alt="Previous day"
          className="mr-4"
        />
      </div>
    </div>
  );
};

export default function CalendarPage() {
  const { user } = React.useContext(UserStateContext);
  const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);
  console.log("test user ", user);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showToast, setShowToast] = React.useState({
    show: false,
    message: "",
    type: "",
  });
  React.useEffect(() => {
    if (showToast.show) {
      const timer = setTimeout(() => {
        setShowToast({
          show: false,
          message: "",
          type: "",
        });
      }, 3000); // delay

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showToast]);
  const [allTools, setAllTools] = React.useState([]);
  const [allBookings, setAllBookings] = React.useState([]);
  const [availbleTools, setAvailbleTools] = React.useState([]);

  const [selectedTools, setSelectedTools] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [projectName, setProjectName] = React.useState("");
  const [projectDesc, setProjectDesc] = React.useState("");
  const [today, setToday] = React.useState(new Date());

  React.useEffect(() => {
    if (!showDrawer) {
      const token = getCookie("access_token");

      const date = moment(today).format("YYYY-MM-DD");
      getBookingsByDate({
        token,
        date,
      }).then(async (r) => {
        if (r.ok) {
          const { data } = await r.json();

          const Bookings = data.map((item) => ({
            id: item.id,
            title: item.projectName,
            desc: item.projectDesc,
            start: new Date(
              moment(item.bookingDate)
                .startOf("day")
                .seconds(item.timeSlot.from)
                .format(),
            ),

            end: new Date(
              moment(item.bookingDate)
                .startOf("day")
                .seconds(item.timeSlot.to)
                .format(),
            ),
            resourceId: item?.tool?.id,
          }));

          setAllBookings(Bookings);
        } else {
          console.log("getBookingsByDate error");
          if (r.status == 401) {
            removeCookie("access_token", "");
          }
        }
      });
      getAllTools({
        token,
      }).then(async (r) => {
        if (r.ok) {
          const { data } = await r.json();
          const toolsoptions = data.map((item) => ({
            text: item.title,
            value: item.id,
            controller: item.controller,
          }));
          setAllTools(toolsoptions);
          const tools = data.map((item) => ({
            resourceId: item?.id,
            resourceTitle: item?.title,
          }));
          setAvailbleTools(tools);
        } else {
          console.log("getAllTools error");
          if (r.status == 401) {
            removeCookie("access_token", "");
          }
        }
      });
    }
  }, [today, showDrawer]);
  const MyToolbar = ({ onNavigate, label, ...props }) => {
    return (
      <div className="flex justify-between mb-10 md:flex-row flex-col">
        <div className="text-2xl font-medium text-white">
          {moment(props.date).format("dddd - MMMM DD, YYYY")}
        </div>
        <div className="flex md:flex-nowrap justify-between  flex-wrap md:mt-0 mt-8">
          <DatePicker
            renderCustomHeader={(props) => <ExampleCustomHeader {...props} />}
            onChange={(date) => {
              onNavigate(props.date, date);
            }}
            customInput={<ExampleCustomInput />}
            calendarClassName="synxus-calander"
            selected={props.date}
          />
          <div className="flex md:mr-9 mx-5 md:mx-0">
            <div
              className="cursor-pointer rounded-l-lg bg-sBlack-txt md:w-44 w-10  text-white  h-10 flex items-center justify-center"
              onClick={() => onNavigate("PREV")}
            >
              <img
                src="/assets/icon/back-arrow.svg"
                alt="Previous day"
                className="md:mr-4"
              />
              <span className="hidden md:block">Previous day</span>
            </div>
            <div
              className="cursor-pointer border-l border-sGray-border bg-sBlack-txt md:w-44 w-auto px-2   text-sGray-border  h-10 flex items-center justify-center"
              onClick={() => onNavigate("TODAY")}
            >
              Today
            </div>
            <div
              className="cursor-pointer border-l border-sGray-border rounded-r-lg bg-sBlack-txt md:w-44 w-10   text-white  h-10 flex items-center justify-center"
              onClick={() => onNavigate("NEXT")}
            >
              <span className="hidden md:block">Next day</span>
              <img
                src="/assets/icon/next-arrow.svg"
                alt="Previous day"
                className="md:ml-4"
              />
            </div>
          </div>
          <Button
            className="flex md:px-4 md:py-2  w-10 justify-center h-10 md:h-auto md:w-auto items-center"
            onClick={() => setShowDrawer(true)}
          >
            <img
              src="/assets/icon/plus.svg"
              alt="Create new tool"
              className="md:pr-3"
            />
            <span className="text-sm font-medium w-full md:block hidden">
              Add a booking
            </span>
          </Button>
        </div>
      </div>
    );
  };

  const tomorrow = new Date();
  tomorrow.setHours(10, 0, 30, 0);
  today.setHours(1, 0, 30, 0);

  const resourceMap = [
    { resourceId: 1, resourceTitle: "Space" },
    { resourceId: 2, resourceTitle: "LATH01" },
    { resourceId: 3, resourceTitle: "LATH02" },
    { resourceId: 4, resourceTitle: "LATH03" },
    { resourceId: 4, resourceTitle: "LATH04" },
    { resourceId: 4, resourceTitle: "LATH04" },
  ];
  return (
    <div>
      <div className="container  my-14">
        <div>
          <Calendar
            events={allBookings}
            localizer={localizer}
            defaultView={Views.DAY}
            step={60}
            onNavigate={(day) => setToday(day)}
            defaultDate={new Date()}
            resources={availbleTools}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            components={{
              event: EventTest,
              toolbar: MyToolbar,
              agenda: {
                event: MyAgendaEvent, // with the agenda view use a different component to render events
              },
            }}
          />
        </div>
      </div>
      <Drawer
        title="New booking"
        visible={showDrawer}
        onClose={() => setShowDrawer((prev) => !prev)}
        onValid={() => {
          if (startTime && endTime && projectName && projectDesc) {
            const token = getCookie("access_token");

            const body = {
              timeSlot: {
                from: startTime,
                to: endTime,
              },
              projectName: projectName,
              projectDesc: projectDesc,
              bookingDate: moment().format("YYYY-MM-DD"),
              status: "pending",
              ownerId: user.id,
              toolId: selectedTools.value,
              buddyEmail: user.email,
            };
            const iscontrolledstatus = selectedTools.controller !== null;
            postBooking({
              token,
              body,
              iscontrolled: iscontrolledstatus,
            }).then(async (r) => {
              if (r.ok) {
                // const { data } = await r.json();
                setShowToast({
                  show: true,
                  message: "Booking successfully created",
                  type: "succes",
                });
                setShowDrawer((prev) => !prev);
              } else {
                const { message } = await r.json();

                setShowToast({
                  show: true,
                  message: Array.isArray(message)
                    ? message.join("\n")
                    : message,
                  type: "danger",
                });
              }
            });
          } else {
            setShowToast({
              show: true,
              message: "Project name or Project description data is missing",
              type: "warning",
            });
          }
        }}
      >
        <Dropdown
          label="Select Tool"
          fullwidth
          options={allTools}
          onSelect={setSelectedTools}
        >
          <Input
            className="cursor-pointer"
            label="Tool"
            placeholder={selectedTools?.text}
            icon="/assets/icon/arrow-down-white.svg"
            disabled
          />
        </Dropdown>

        <div className="mt-5 flex">
          <TimePicker
            label="Start time"
            className="mr-1"
            onChangeTime={(value) =>
              setStartTime(moment.duration(value, "hh:mm a").as("seconds"))
            }
          />
          <TimePicker
            label="End time"
            className="ml-1"
            onChangeTime={(value) =>
              setEndTime(moment.duration(value, "hh:mm a").as("seconds"))
            }
          />
        </div>
        <Input
          label="Project name"
          className="mt-5"
          placeholder="Device ID"
          onChange={(event) => setProjectName(event.target.value)}
        />
        <label
          htmlFor="project-desc"
          className="block text-sm font-medium text-white mt-5"
        >
          Project description
        </label>
        <textarea
          onChange={(event) => setProjectDesc(event.target.value)}
          placeholder="Describe your project here"
          cols="30"
          rows="6"
          name="project-desc"
          className="text-gray-500 bg-transparent px-3 py-2 mt-1 focus:ring-indigo-500 focus:border-sGray-border focus-visible:border-sGray-border block w-full shadow-sm sm:text-sm border-sGray-border border rounded-md"
        />
      </Drawer>
      {showToast.show ? (
        <Toast message={showToast.message} type={showToast.type} />
      ) : null}
    </div>
  );
}
