import React from "react";
import { useCookies } from "react-cookie";
import { Checkbox, Button, Input, Toast } from "../../components";

import {
  UserDispatchContext,
  UserStateContext,
} from "../../contexts/userContext";

import { restPass, editProfil } from "../../utils/api";
import getCookie from "../../utils/getcookie";

export default function Profile() {
  const { user } = React.useContext(UserStateContext);
  const UserDispatch = React.useContext(UserDispatchContext);
  // eslint-disable-next-line no-unused-vars
  const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);

  const [showToast, setShowToast] = React.useState({
    show: false,
    message: "",
    type: "",
  });
  React.useEffect(() => {
    if (showToast.show) {
      const timer = setTimeout(() => {
        setShowToast({
          show: false,
          message: "",
          type: "",
        });
      }, 3000); // delay

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showToast]);
  const [menu, setMenu] = React.useState(0);
  const [pinCode, setPinCode] = React.useState("");

  const onSubmitGeneralInfo = (event) => {
    event.preventDefault();
    const token = getCookie("access_token");

    const body = {
      firstName: event.target[0].value,
      lastName: event.target[1].value,
      email: event.target[2].value,
      phone: event.target[3].value,
    };

    editProfil({ token, body, id: user.id }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();

        UserDispatch({ type: "set-user", payload: data });
        setShowToast({
          show: true,
          message: "Profile successfully updated",
          type: "succes",
        });
      } else {
        const { message } = await r.json();

        setShowToast({
          show: true,
          message: Array.isArray(message) ? message.join("\n") : message,
          type: "danger",
        });
      }
    });
  };

  const onSubmitPassword = (event) => {
    event.preventDefault();
    const token = getCookie("access_token");

    const body = {
      password: event.target[0].value,
      passwordConfirmation: event.target[1].value,
      token: token,
    };

    restPass({ token, body }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        console.log("restPass ok", data);
      } else {
        console.log("postBooking error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  };
  return (
    <div>
      <div className="container  my-14 flex md:flex-row flex-col">
        <div className="md:w-3/12 w-full md:mr-4 md:bg-transparent bg-sBlack-txt mb-5 md:mb-0 p-4 md:p-0 rounded-md md:rounded-none">
          <div
            className={`text-sm font-medium cursor-pointer px-3 py-2  ${
              menu === 0
                ? "bg-sGreen text-sGray rounded-md"
                : "text-sGray-light"
            }`}
            onClick={() => setMenu(0)}
          >
            General informations
          </div>
          <div
            className={`text-sm font-medium cursor-pointer px-3 py-2  ${
              menu === 1
                ? "bg-sGreen text-sGray rounded-md"
                : "text-sGray-light"
            }`}
            onClick={() => setMenu(1)}
          >
            Security
          </div>
          <div
            className={`text-sm font-medium cursor-pointer px-3 py-2  ${
              menu === 2
                ? "bg-sGreen text-sGray rounded-md"
                : "text-sGray-light"
            }`}
            onClick={() => setMenu(2)}
          >
            Working hours
          </div>
        </div>

        <div className="md:w-9/12 w-full bg-black md:ml-4 rounded-md md:px-10 px-4 py-8">
          {menu === 0 ? (
            <form onSubmit={(event) => onSubmitGeneralInfo(event)}>
              <div className="text-sGreen text-lg pb-5 mb-5 font-medium border-sGray-border border-b">
                General information
              </div>
              <div className="md:flex items-center  pb-5 mb-5 border-sGray-border border-b">
                <label
                  htmlFor="project-desc"
                  className="block text-sm font-medium text-sGray-light md:w-1/3"
                >
                  First name
                </label>
                <div className="md:w-1/3">
                  <Input
                    placeholder="First name"
                    defaultValue={user?.firstName}
                    name="firstName"
                  />
                </div>
              </div>
              <div className="md:flex items-center  pb-5 mb-5 ">
                <label
                  htmlFor="project-desc"
                  className="block text-sm font-medium text-sGray-light md:w-1/3"
                >
                  Last name
                </label>
                <div className="md:w-1/3">
                  <Input
                    placeholder="Last name"
                    name="lastName"
                    defaultValue={user?.lastName}
                  />
                </div>
              </div>
              {/* 
                  border-sGray-border border-b
                  TODO: add api change profile
                  <div className="md:flex items-center mb-16">
                    <label
                      htmlFor="project-desc"
                      className="block text-sm font-medium text-sGray-light md:w-1/3"
                    >
                      Avatar
                    </label>
                    <div className="md:w-1/3 flex items-center">
                      <img
                        src="/assets/img/avatar.jpg"
                        alt="avatar"
                        className="h-12 w-12 rounded border-sGreen border ml-4"
                      />
                      <div className="overflow-hidden relative w-20 mt-4 mb-4 ml-5">
                        <Button className=" text-center text-sm font-medium w-full px-3 py-2">
                          Change
                        </Button>
                        <input
                          className="cursor-pointer absolute block py-2 px-4 w-full opacity-0 top-0 left-0"
                          type="file"
                          name="documents[]"
                          accept="image/*"
                        />
                      </div>
                      <div className=" text-sm font-medium text-sRed  ml-5">Delete</div>
                    </div>
                  </div> */}
              <div className="text-sGreen text-lg pb-5 mb-5 font-medium border-sGray-border border-b">
                Contact information
              </div>
              <div className="md:flex items-center  pb-5 mb-5 border-sGray-border border-b">
                <label
                  htmlFor="project-desc"
                  className="block text-sm font-medium text-sGray-light md:w-1/3"
                >
                  Email address
                </label>
                <div className="md:w-1/3">
                  <Input
                    placeholder="Email address"
                    defaultValue={user?.email}
                    name="email"
                  />
                </div>
              </div>
              <div className="md:flex items-center mb-5">
                <label
                  htmlFor="project-desc"
                  className="block text-sm font-medium text-sGray-light md:w-1/3"
                >
                  Phone number
                </label>
                <div className="md:w-1/3">
                  <Input
                    placeholder="Phone number"
                    defaultValue={user?.phone}
                    name="phone"
                  />
                </div>
              </div>
              <div className="flex justify-center mt-5">
                <Button className=" text-center text-sm font-medium  px-3 py-2">
                  <button type="submit">Save changes</button>
                </Button>
              </div>
            </form>
          ) : menu === 1 ? (
            <div>
              <div className="text-sGreen text-lg pb-5 mb-5 font-medium border-sGray-border border-b">
                Change your password
              </div>
              <form onSubmit={(event) => onSubmitPassword(event)}>
                <div className="md:flex items-center  pb-5 mb-5 border-sGray-border border-b">
                  <label
                    htmlFor="project-desc"
                    className="block text-sm font-medium text-sGray-light md:w-1/3"
                  >
                    Current password
                  </label>
                  <div className="md:w-1/3">
                    <Input
                      className="my-5"
                      icon="/assets/icon/eye.svg"
                      placeholder="your Password"
                      password
                    />
                  </div>
                </div>
                <div className="md:flex items-center  pb-5 mb-16 border-sGray-border border-b">
                  <label
                    htmlFor="project-desc"
                    className="block text-sm font-medium text-sGray-light md:w-1/3"
                  >
                    New password
                  </label>
                  <div className="md:w-1/3">
                    <Input
                      className="my-5"
                      icon="/assets/icon/eye.svg"
                      password
                      placeholder="your Password"
                    />
                  </div>
                  <div className="md:w-1/3 flex justify-end md:pr-5">
                    <Button className=" text-center text-sm font-medium px-3 py-2 md:w-auto w-full">
                      <button type="submit">Confirm</button>
                    </Button>
                  </div>
                </div>
              </form>
              <div className="text-sGreen text-lg pb-5 mb-5 font-medium border-sGray-border border-b">
                Change your PIN
              </div>
              <div className="md:flex items-center  pb-5 mb-5 ">
                <label
                  htmlFor="project-desc"
                  className="block text-sm font-medium text-sGray-light md:w-1/3"
                >
                  New PIN
                </label>
                <div className="md:w-1/3">
                  <Input
                    placeholder="New PIN"
                    className="my-5"
                    onChange={(event) => setPinCode(event.target.value)}
                  />
                </div>
                <div className="md:w-1/3 flex justify-end md:pr-5">
                  <Button
                    className=" text-center text-sm font-medium px-3 py-2 md:w-auto w-full"
                    onClick={() => console.log("test", pinCode)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          ) : menu === 2 ? (
            <div>
              <div className="text-sGreen text-lg pb-5 mb-5 font-medium border-sGray-border border-b">
                Manage your working hours
              </div>
              <div className="md:flex justify-center">
                <Checkbox label="Same hours everyday" />
                <Checkbox
                  label="Available during the weekend"
                  className="md:ml-5"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {showToast.show ? (
        <Toast message={showToast.message} type={showToast.type} />
      ) : null}
    </div>
  );
}
