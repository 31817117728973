import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Dropdown, Button, Loader } from "../../components";
import { getOldBookings } from "../../utils/api";
import getCookie from "../../utils/getcookie";
import { useCookies } from "react-cookie";

export default function History() {
  const [isLoading, setIsLoading] = React.useState(true);

  const [openFilter, setOpenFilter] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [allTools, setAllTools] = React.useState([]);
  const [allspaces, setAllSpaces] = React.useState([]);

  const [queryDate, setQueryDate] = React.useState("");
  const [queryTool, setQueryTool] = React.useState("");
  const [querySpace, setQuerySpace] = React.useState("");
  const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);

  const role = [
    "Owner",
    "Lab Manager",
    "Hub Manager",
    "Super User",
    "Normal User",
  ];
  React.useEffect(() => {
    const token = getCookie("access_token");

    getOldBookings({
      token,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        const toolsoptions = data.map((item) => ({
          text: item?.tool?.title,
          id: item?.tool?.id,
        }));
        const spacesoptions = data.map((item) => ({
          text: item?.tool?.controller?.space?.title,
          id: item?.tool?.controller?.space?.id,
        }));
        setAllTools(toolsoptions);
        setAllSpaces(spacesoptions);
        setUsersData(data); // TODO: fix user api
        setIsLoading(false);
      } else {
        console.log("getOldBookings error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  }, []);

  React.useEffect(() => {
    const token = getCookie("access_token");

    getOldBookings({
      token,
      toolId: queryTool,
      spaceId: querySpace,
      date: queryDate,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();

        setUsersData(data); // TODO: fix user api
        setIsLoading(false);
      } else {
        console.log("getOldBookings error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  }, [queryDate, queryTool, querySpace]);

  const CustomHeader = ({ ...props }) => {
    return (
      <div className="flex items-center justify-between text-sm font-medium text-white mb-7">
        <div onClick={props.decreaseMonth} className="cursor-pointer">
          <img
            src="/assets/icon/back-arrow.svg"
            alt="back-arrow"
            className="mr-4"
          />
        </div>
        {moment(props.date).format("MMMM - YYYY")}
        <div onClick={props.increaseMonth} className="cursor-pointer">
          <img
            src="/assets/icon/next-arrow.svg"
            alt="next-arrow"
            className="mr-4"
          />
        </div>
      </div>
    );
  };
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
      className="cursor-pointer rounded-lg bg-sBlack-txt p-0  text-white  h-10 flex items-center  md:justify-center justify-between md:mr-5 mr-0"
      id="menu-button"
      aria-expanded="true"
      aria-haspopup="true"
      type="button"
      onClick={onClick}
      ref={ref}
    >
      <span className="px-4">Usage Date</span>
      <img
        src="/assets/icon/arrow-down-white.svg"
        alt="Previous day"
        className="border-l h-full p-2 border-sGray-border"
      />
    </div>
  ));
  return (
    <div>
      <div className="container  my-14">
        <div className="flex justify-between  md:flex-row flex-wrap">
          <div className="text-2xl font-medium text-white">User History</div>
          <Button
            className="bg-sBlack-txt  flex px-4 py-2 w-auto items-center md:hidden"
            onClick={() => setOpenFilter((prev) => !prev)}
          >
            <img
              src={
                openFilter
                  ? "/assets/icon/close-yellow.svg"
                  : "/assets/icon/filter-yellow.svg"
              }
              alt="Create new tool"
              className="md:pr-3"
            />
            <span className="text-sm font-medium w-full text-white ml-2">
              Filter
            </span>
          </Button>
          <div
            className={`md:flex md:flex-row flex-col w-full md:w-auto md:mt-0 mt-5 ${
              openFilter ? "flex " : "hidden"
            }`}
          >
            <DatePicker
              renderCustomHeader={(props) => <CustomHeader {...props} />}
              onChange={(date) => {
                setQueryDate(moment(date).format("YYYY-MM-DD"));
              }}
              customInput={<CustomInput />}
              calendarClassName="synxus-calander"
            />
            <Dropdown
              label="Select Tool"
              onSelect={(item) => setQueryTool(item.id)}
              options={allTools}
              className="md:mr-5 mr-0 my-5 md:my-0"
            />
            <Dropdown
              label="Select Space"
              options={allspaces}
              onSelect={(item) => setQuerySpace(item.id)}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            className={`overflow-auto pb-5 md:pb-0 ${
              openFilter ? "hidden " : "block"
            }`}
          >
            <table className="rounded-lg w-full mt-14 bg-sBlack-txt">
              <tr className="text-left border-b border-sGray-border text-sGreen text-xs font-medium  uppercase">
                <th className=" px-6 py-3">user</th>
                <th className=" px-6 py-3">Role</th>
                <th className=" px-6 py-3">Space</th>
                <th className=" px-6 py-3">Tool</th>

                <th className=" px-6 py-3">Booked on</th>
                <th className=" px-6 py-3">Used From</th>

                <th className=" px-6 py-3">Used to</th>
              </tr>
              {usersData &&
                usersData.map((item, index) => (
                  <tr
                    className={
                      index + 1 !== usersData.length
                        ? " border-b border-sGray-border"
                        : ""
                    }
                  >
                    <td className=" px-6 py-3 flex items-center">
                      <img
                        src="/assets/img/avatar.jpg"
                        alt="avatar"
                        className="h-10 w-10 rounded-full  mr-4"
                      />
                      <div>
                        <div className="text-sm font-medium text-white">
                          {item?.owner?.firstName}&nbsp;{item?.owner?.lastName}
                        </div>
                        <div className="text-sm font-normal text-gray-500">
                          {item?.owner?.email}
                        </div>
                      </div>
                    </td>
                    <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                      {role[item?.owner?.role]}
                    </td>
                    <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                      {item?.tool?.controller?.space?.title}
                    </td>
                    <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                      {item?.tool?.title}
                    </td>
                    <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                      {moment(item?.createdAt).format(
                        "YYYY-MM-DD [at] h:mm[PM]",
                      )}
                    </td>
                    <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                      {moment(item?.bookingDate)
                        .startOf("day")
                        .seconds(item?.timeSlot?.from)
                        .format("YYYY-MM-DD [at] h:mm[PM]")}
                    </td>
                    <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                      {moment(item?.bookingDate)
                        .startOf("day")
                        .seconds(item?.timeSlot?.to)
                        .format("YYYY-MM-DD [at] h:mm[PM]")}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
