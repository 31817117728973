import React from "react";
export default function Dropdown({
  label,
  className,
  values,
  children,
  fullwidth,
  options,
  onSelect,
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setvalue] = React.useState();
  const wrapperRef = React.useRef();

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      className={`relative inline-block text-left ${fullwidth ? "w-full" : ""}`}
      ref={wrapperRef}
    >
      {children ? (
        <div onClick={() => setOpen((prev) => !prev)}>{children}</div>
      ) : (
        <div
          className={`cursor-pointer rounded-lg bg-sBlack-txt p-0  text-white  h-10 flex items-center md:justify-center justify-between ${className} `}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          type="button"
          onClick={() => setOpen((prev) => !prev)}
        >
          <span className="px-4">{value || label}</span>
          <img
            src="/assets/icon/arrow-down-white.svg"
            alt="Previous day"
            className="border-l h-full p-2 border-sGray-border"
          />
        </div>
      )}

      {open ? (
        <div
          className={`origin-top-right absolute right-0 md:mt-2 mt-0  rounded-md shadow-lg bg-sBlack-light ring-1 ring-black ring-opacity-5 focus:outline-none border border-sGray-border z-50 ${
            fullwidth ? "w-full" : " w-full md:w-64"
          }`}
        >
          <div className="py-1" role="none">
            <div className="text-sGreen block py-2 px-4 text-sm font-medium ">
              {label}
            </div>

            {options
              ? options.map((item, index) => (
                  <div
                    onClick={() => {
                      onSelect(item);
                      setOpen(false);
                    }}
                    className="text-sGray-light block py-2 px-4 text-sm font-normal hover:bg-sGreen hover:text-sBlack cursor-pointer"
                    key={index}
                  >
                    {item.text}
                  </div>
                ))
              : values.map((item, index) => (
                  <div
                    onClick={() => {
                      onSelect(item);
                      setvalue(item);
                      setOpen(false);
                    }}
                    className="text-sGray-light block py-2 px-4 text-sm font-normal hover:bg-sGreen hover:text-sBlack cursor-pointer"
                    key={index}
                  >
                    {item}
                  </div>
                ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
