import React from "react";

import { NavLink } from "react-router-dom";
import { Button } from "./index";
import { UserDispatchContext, UserStateContext } from "../contexts/userContext";
import { useCookies } from "react-cookie";
import { getUserById } from "../utils/api";

function Navbar() {
  const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);

  const [menuProfile, setMenuProfile] = React.useState(false);
  const [menuMobile, setMenuMobile] = React.useState(false);
  const { user } = React.useContext(UserStateContext);
  const profileRef = React.useRef();

  const UserDispatch = React.useContext(UserDispatchContext);

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setMenuProfile(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  React.useEffect(() => {
    getUserById({
      token: cookies["access_token"],
      id: cookies["_idUser"],
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();

        UserDispatch({ type: "set-user", payload: data });
      } else {
        console.log("getUserById error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  }, []);

  return (
    <div className="h-16 bg-sBlack-txt w-full ">
      <div className="container flex items-center w-full h-full justify-between">
        <div className="flex items-center h-full">
          <img src="/assets/line-logo.svg" alt="logo" />
          <div className="text-sm  font-medium h-full ml-28 text-sGray-light hidden md:flex">
            <NavLink
              to="/dashboard/calendar"
              className=" flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen px-1 mr-5 box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
            >
              Calendars
            </NavLink>
            <NavLink
              to="/dashboard/training"
              className=" flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen px-1 mr-5 box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
            >
              Training
            </NavLink>
            <NavLink
              to="/dashboard/manage-tools"
              className=" flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen px-1 mr-5 box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
            >
              Manage tools
            </NavLink>
            <NavLink
              to="/dashboard/users"
              className=" flex items-center  border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen px-1 mr-5 box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
            >
              Manage users
            </NavLink>
            <NavLink
              to="/dashboard/history"
              className=" flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen px-1 mr-5 box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
            >
              History
            </NavLink>
            <NavLink
              to="/dashboard/new-booking"
              className=" flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen px-1 mr-5 box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
            >
              New booking
            </NavLink>
          </div>
        </div>
        <div
          className="md:hidden flex items-center justify-center p-2 bg-sBlack rounded-md z-50"
          onClick={() => setMenuMobile((prev) => !prev)}
        >
          <img
            src={
              menuMobile ? "/assets/icon/close.svg" : "/assets/icon/menu.svg"
            }
            alt="selector"
            className=""
          />
        </div>
        <div className="items-center h-full  hidden md:flex">
          <div className="border-sGreen border-r pr-5 text-sGray-light flex  items-center text-sm  font-medium mr-5">
            <div>{user?.hub?.title || "Storybrook Hub"}</div>
            <img
              src="/assets/icon/selector.svg"
              alt="selector"
              className="ml-2"
            />
          </div>
          <div className="relative" ref={profileRef}>
            <div
              onClick={() => setMenuProfile((prev) => !prev)}
              className="flex  items-center cursor-pointer"
            >
              <div className="text-sGray-light text-sm  font-medium">
                {user?.firstName || ""}
              </div>
              <img
                src="/assets/img/avatar.jpg"
                alt="avatar"
                className="h-8 w-8 rounded border-sGreen border ml-4"
              />
              <img
                src="/assets/icon/arrow-down.svg"
                alt="avatar"
                className=" ml-3"
              />
            </div>
            {menuProfile ? (
              <div className=" origin-top-right absolute top-10 right-0 mt-2  w-64 rounded-md shadow-lg bg-sBlack-light ring-1 ring-black ring-opacity-5 focus:outline-none border border-sGray-border z-50">
                <div className="py-1" role="none">
                  <NavLink
                    onClick={() => setMenuProfile(false)}
                    to="/dashboard/profile"
                    className="text-sGray-light block py-2 px-4 text-sm font-normal hover:bg-sGreen hover:text-sBlack cursor-pointer border-sGray-border border-b"
                  >
                    Go to my profile
                  </NavLink>
                  <div
                    className="text-sGray-light block py-2 px-4 text-sm font-normal hover:bg-sGreen hover:text-sBlack cursor-pointer "
                    onClick={() => {
                      removeCookie("access_token", "");
                    }}
                  >
                    Logout
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {menuMobile ? (
        <div className="bg-sBlack-txt absolute  top-16 bottom-0 left-0 right-0 z-50 p-4 flex flex-col">
          <div className="text-lg  font-medium   text-sGray-light ">
            <NavLink
              to="/dashboard/calendar"
              className="mb-5 flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen  box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
              onClick={() => setMenuMobile(false)}
            >
              Calendars
            </NavLink>
            <NavLink
              to="/dashboard/training"
              className="mb-5 flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen   box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
              onClick={() => setMenuMobile(false)}
            >
              Training
            </NavLink>
            <NavLink
              to="/dashboard/manage-tools"
              className="mb-5 flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen  box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
              onClick={() => setMenuMobile(false)}
            >
              Manage tools
            </NavLink>
            <NavLink
              to="/dashboard/users"
              className="mb-5 flex items-center  border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen  box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
              onClick={() => setMenuMobile(false)}
            >
              Manage users
            </NavLink>
            <NavLink
              to="/dashboard/history"
              className="mb-5 flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
              onClick={() => setMenuMobile(false)}
            >
              History
            </NavLink>
            <NavLink
              to="/dashboard/new-booking"
              className="mb-5 flex items-center border-transparent border-b-2  hover:text-sGreen  hover:border-b-2 hover:border-sGreen box-border"
              activeClassName="text-sGreen border-sGreen border-b-2 "
              onClick={() => setMenuMobile(false)}
            >
              New booking
            </NavLink>
          </div>
          <div className=" mt-auto">
            <NavLink
              onClick={() => setMenuMobile(false)}
              to="/dashboard/profile"
              className="px-4 py-2 border-sGreen rounded-md w-full text-center text-sGreen block text-sm font-semibold mb-5 hover:bg-sGreen hover:text-sBlack cursor-pointer  border-2"
            >
              Go to my profile
            </NavLink>

            <Button
              className="  font-semibold px-4 py-2 w-full text-center mb-0"
              onClick={() => {
                removeCookie("access_token", "");
              }}
            >
              Logout
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default Navbar;
