import React from "react";

export default function CardTool({ Template, data, ...props }) {
  return (
    <div className="bg-sBlack-txt rounded-lg " {...props}>
      <div className="md:px-5 px-4 py-6">
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <div className="text-white font-medium text-sm mr-3">
              {data?.title || null}
            </div>
            {!Template ? (
              <div className="bg-sGreen-light py-1 px-2 text-sGreen text-xs font-medium flex rounded-sm">
                <img
                  src="/assets/icon/view-grid.svg"
                  alt="time"
                  className="mr-1"
                />
                {data?.template?.title || null}
              </div>
            ) : null}
          </div>
          <img
            src={
              Template
                ? "/assets/icon/arrow-down.svg"
                : "/assets/icon/settings.svg"
            }
            alt="time"
            className=""
          />
        </div>
        {Template ? (
          <div className="text-sGray-light mt-2">{data?.description}</div>
        ) : (
          <div className="text-sGray-light mt-2">
            <b>Device ID</b> {data?.id || null}
          </div>
        )}
      </div>
      <div className="border-t border-sGray-border md:px-9 px-3 py-6 flex justify-between">
        <div className="text-sm text-sGreen flex items-center font-normal">
          <img src="/assets/icon/clock.svg" alt="time" className="mr-2" />
          {Template ? data?.minWorkTime : data?.template?.minWorkTime}-
          {Template ? data?.maxWorkTime : data?.template?.maxWorkTime}
        </div>
        <div alt="time" className="mr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4.35418C12.7329 3.52375 13.8053 3 15 3C17.2091 3 19 4.79086 19 7C19 9.20914 17.2091 11 15 11C13.8053 11 12.7329 10.4762 12 9.64582M15 21H3V20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21ZM15 21H21V20C21 16.6863 18.3137 14 15 14C13.9071 14 12.8825 14.2922 12 14.8027M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
              stroke={
                data?.buddyMode || data?.template?.buddyMode
                  ? "#CEDB2C"
                  : "#9CA4B4"
              }
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div alt="availableInWorkingHours" className="mr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 11V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21Z"
              stroke={
                data?.availableInWorkingHours ||
                data?.template?.availableInWorkingHours
                  ? "#CEDB2C"
                  : "#9CA4B4"
              }
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div alt="requires-training" className="mr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 14L21 9L12 4L3 9L12 14ZM12 14L18.1591 10.5783C18.7017 11.9466 19 13.4384 19 14.9999C19 15.7013 18.9398 16.3885 18.8244 17.0569C16.2143 17.3106 13.849 18.4006 12 20.0555C10.151 18.4006 7.78571 17.3106 5.17562 17.0569C5.06017 16.3885 5 15.7012 5 14.9999C5 13.4384 5.29824 11.9466 5.84088 10.5782L12 14ZM8 19.9999V12.5L12 10.2778"
              stroke={
                data?.requiresTraining || data?.template?.requiresTraining
                  ? "#CEDB2C"
                  : "#9CA4B4"
              }
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div alt="input-files" className="mr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 13H15M12 10L12 16M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z"
              stroke={
                data?.inputFiles || data?.template?.inputFiles
                  ? "#CEDB2C"
                  : "#9CA4B4"
              }
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div alt="sound" className="mr-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.63604 18.3642C2.12132 14.8494 2.12132 9.15095 5.63604 5.63623M18.364 5.63623C21.8787 9.15095 21.8787 14.8494 18.364 18.3642M8.46447 15.5357C6.51184 13.5831 6.51184 10.4173 8.46447 8.46466M15.5355 8.46466C17.4882 10.4173 17.4882 13.5831 15.5355 15.5357M13 12.0002C13 12.5525 12.5523 13.0002 12 13.0002C11.4477 13.0002 11 12.5525 11 12.0002C11 11.4479 11.4477 11.0002 12 11.0002C12.5523 11.0002 13 11.4479 13 12.0002Z"
              stroke={
                data?.sound || data?.template?.sound ? "#CEDB2C" : "#9CA4B4"
              }
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
