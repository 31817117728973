import React from "react";
import { getAllTrainings, patchTrainingStatus } from "../../utils/api";
import getCookie from "../../utils/getcookie";
// import {
//   UserDispatchContext,
//   UserStateContext,
// } from "../../contexts/userContext";
import { Loader } from "../../components";
import { useCookies } from "react-cookie";

export default function Training() {
  const [isLoading, setIsLoading] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);

  const [relode, setRelode] = React.useState(false);
  const [usersTrainingsToReview, setUsersTrainingsToReview] = React.useState(
    [],
  );
  const [usersTrainingsReviewed, setUsersTrainingsReviewed] = React.useState(
    [],
  );
  const token = getCookie("access_token");
  // const { user } = React.useContext(UserStateContext);
  const role = [
    "Owner",
    "Lab Manager",
    "Hub Manager",
    "Super User",
    "Normal User",
  ];

  React.useEffect(() => {
    getAllTrainings({
      token,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        setIsLoading(false);
        setUsersTrainingsToReview(
          data.filter((user) => user.status === "pending"),
        );
        setUsersTrainingsReviewed(
          data.filter((user) => user.status !== "pending"),
        );
      } else {
        console.log("getAllTrainings error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  }, [token, relode]);
  const actionsTrainings = (id, status) => {
    patchTrainingStatus({
      token,
      id,
      body: {
        status,
      },
    }).then(async (r) => {
      if (r.ok) {
        // const { data } = await r.json();
        setRelode((prev) => !prev);
      } else {
        console.log("patchTrainingStatus error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  };
  return (
    <div>
      <div className="container  my-14">
        <div className="text-2xl font-medium text-white">
          Trainings to review
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className=" overflow-auto pb-5 md:pb-0            ">
            <table className="rounded-lg w-full mt-14 bg-sBlack-txt">
              <tr className="text-left border-b border-sGray-border text-sGreen text-xs font-medium  uppercase">
                <th className=" px-6 py-3">Score</th>
                <th className=" px-6 py-3">user</th>
                <th className=" px-6 py-3">Role</th>
                <th className=" px-6 py-3">Actions</th>
              </tr>
              {usersTrainingsToReview.map((item, index) => (
                <tr
                  className={
                    index + 1 !== usersTrainingsToReview.length
                      ? " border-b border-sGray-border"
                      : ""
                  }
                >
                  <td className=" px-6 py-3 text-sm font-medium text-white">
                    {item?.score}
                  </td>
                  <td className=" px-6 py-3 flex items-center">
                    <img
                      src="/assets/img/avatar.jpg"
                      alt="avatar"
                      className="h-10 w-10 rounded-full  mr-4"
                    />
                    <div>
                      <div className="text-sm font-medium text-white">
                        {item?.user?.firstName}&nbsp;{item?.user?.lastName}
                      </div>
                      <div className="text-sm font-normal text-gray-500">
                        {item?.user?.email}
                      </div>
                    </div>
                  </td>
                  <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                    {role[parseInt(item?.user?.role) + 1]}
                  </td>
                  <td className=" px-6 py-3">
                    <div className=" flex">
                      <div
                        className="flex px-4  w-auto items-center mr-5 bg-sGreen-accept text-white rounded-md py-2 text-center cursor-pointer"
                        onClick={() => {
                          actionsTrainings(item.id, "approved");
                        }}
                      >
                        <img
                          src="/assets/icon/check.svg"
                          alt="Create new tool"
                          className="pr-3"
                        />
                        <span className="text-sm font-medium w-full">
                          Accept
                        </span>
                      </div>
                      <div
                        className="flex px-4  w-auto items-center mr-5 bg-sRed-reject text-white rounded-md py-2 text-center cursor-pointer"
                        onClick={() => {
                          actionsTrainings(item.id, "declined");
                        }}
                      >
                        <img
                          src="/assets/icon/reject.svg"
                          alt="Create new tool"
                          className="pr-3"
                        />
                        <span className="text-sm font-medium w-full">
                          Reject
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}

        <div className="text-2xl font-medium text-white mt-16">Reviewed</div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className=" overflow-auto pb-5 md:pb-0            ">
            <table className="rounded-lg w-full mt-14 bg-sBlack-txt">
              <tr className="text-left border-b border-sGray-border text-sGreen text-xs font-medium  uppercase">
                <th className=" px-6 py-3">Score</th>
                <th className=" px-6 py-3">user</th>
                <th className=" px-6 py-3">Role</th>
                <th className=" px-6 py-3">Actions</th>
              </tr>

              {usersTrainingsReviewed.map((item, index) => (
                <tr
                  className={
                    index + 1 !== usersTrainingsReviewed.length
                      ? " border-b border-sGray-border"
                      : ""
                  }
                >
                  <td className=" px-6 py-3 text-sm font-medium text-white">
                    {item?.score}{" "}
                  </td>
                  <td className=" px-6 py-3 flex items-center">
                    <img
                      src="/assets/img/avatar.jpg"
                      alt="avatar"
                      className="h-10 w-10 rounded-full  mr-4"
                    />
                    <div>
                      <div className="text-sm font-medium text-white">
                        {item?.user?.firstName}&nbsp;{item?.user?.lastName}
                      </div>
                      <div className="text-sm font-normal text-gray-500">
                        {item?.user?.email}
                      </div>
                    </div>
                  </td>
                  <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                    Hub Manager
                  </td>
                  {item?.status === "approved" ? (
                    <td className=" px-6 py-3 text-sm font-medium text-sGreen-accept mr-4	 h-full">
                      Accepted
                    </td>
                  ) : (
                    <td className=" px-6 py-3 text-sm font-medium text-sRed-reject mr-4	 h-full">
                      Rejected
                    </td>
                  )}
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
