import React from "react";
import { Button } from "./index";
export default function Drawer({ visible, onClose, children, onValid, title }) {
  return (
    <div
      className={`absolute right-0 top-0 bottom-0 bg-sBlack w-full bg-opacity-70 z-50 ${
        visible ? "block" : "hidden"
      }`}
    >
      <div className=" md:w-98  w-full  bg-sBlack h-full absolute right-0 md:px-12 px-4 md:py-14 py-5">
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-medium text-white">{title}</div>
            <img
              src="/assets/icon/close.svg"
              alt="close"
              className="h-full cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div className="mt-14 overflow-auto pr-2 mb-2 h-full">{children}</div>
          <Button
            className=" px-4 py-2 w-full text-center mt-auto mb-0"
            onClick={onValid}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
