import React from "react";
import { useCookies } from "react-cookie";
import {
  Dropdown,
  Button,
  Modal,
  Input,
  Drawer,
  Loader,
  Toast,
} from "../../components";
import {
  getAllUsers,
  promoteUser,
  patchPin,
  deleteUser,
  uploadUser,
  getAllHubs,
  postUser,
} from "../../utils/api";
import getCookie from "../../utils/getcookie";

export default function Users() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);

  const [usersData, setUsersData] = React.useState([]);
  const [allHubs, setAllHubs] = React.useState([]);

  const [showModal, setShowModal] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showToast, setShowToast] = React.useState({
    show: false,
    message: "",
    type: "",
  });
  React.useEffect(() => {
    if (showToast.show) {
      const timer = setTimeout(() => {
        setShowToast({
          show: false,
          message: "",
          type: "",
        });
      }, 3000); // delay

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showToast]);

  const [pinCode, setPinCode] = React.useState({});

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [hub, setHub] = React.useState({});

  const role = [
    "Owner",
    "Lab Manager",
    "Hub Manager",
    "Super User",
    "Normal User",
  ];
  React.useEffect(() => {
    const token = getCookie("access_token");

    getAllUsers({
      token,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        setUsersData(data.users); // TODO: fix user api
        setIsLoading(false);
      } else {
        console.log("getAllUsers error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });

    getAllHubs({
      token,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        const hubsoptions = data.map((item) => ({
          text: item.title,
          value: item.id,
          organization: item.organization.id,
        }));
        setAllHubs(hubsoptions);
      } else {
        console.log("getAllUsers error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  }, [showToast]);

  const ChangeRole = ({ id }) => (
    <Dropdown
      options={[
        { text: "Owner", id: -1 },
        { text: "Lab Manager", id: 0 },
        { text: "Hub Manager", id: 1 },
        { text: "Super User", id: 2 },
        { text: "Normal User", id: 3 },
      ]}
      label="Select a role"
      onSelect={(item) => {
        const token = getCookie("access_token");
        const body = {
          role: item.id,
        };

        promoteUser({
          token,
          id,
          body,
        }).then(async (r) => {
          if (r.ok) {
            setShowToast({
              show: true,
              message: "Role successfully changed",
              type: "succes",
            });
          } else {
            const { message } = await r.json();

            setShowToast({
              show: true,
              message: Array.isArray(message) ? message.join("\n") : message,
              type: "danger",
            });
          }
        });
      }}
    >
      <div className="text-sm font-medium text-sGreen flex items-center  h-full cursor-pointer">
        <span> Change Role</span>
        <img src="/assets/icon/arrow-down.svg" alt="avatar" className=" ml-3" />
      </div>
    </Dropdown>
  );
  return (
    <div>
      <div className="container  my-14">
        <div className="flex justify-between">
          <div className="text-2xl font-medium text-white">List of users</div>
          <div className="flex">
            <Button
              className="flex px-4 py-2 w-auto items-center mr-5"
              onClick={() => setShowDrawer(true)}
            >
              <img
                src="/assets/icon/add-user.svg"
                alt="Create new tool"
                className="md:pr-3"
              />
              <span className="text-sm font-medium w-full hidden md:block">
                Create new user
              </span>
            </Button>

            <label className="bg-sGreen text-sBlack-txt rounded-md  text-center cursor-pointer flex px-4 py-2 w-auto items-center">
              <img
                src="/assets/icon/doc-download.svg"
                alt="Create new tool"
                className="md:pr-3"
              />
              <span className="text-sm font-medium w-full hidden md:block">
                Import .csv
              </span>
              <input
                type="file"
                className="hidden"
                onChange={(e) => {
                  uploadUser({
                    body: e.target.files,
                  }).then(async (r) => {
                    if (r.ok) {
                      const { data } = await r.json();
                      console.log("uploadUser", data);
                    } else {
                      console.log("uploadUser error");
                      if (r.status == 401) {
                        removeCookie("access_token", "");
                      }
                    }
                  });
                }}
              />
            </label>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className=" overflow-auto pb-5 md:pb-0            ">
            <table className="rounded-lg w-full mt-14 bg-sBlack-txt">
              <tr className="text-left border-b border-sGray-border text-sGreen text-xs font-medium  uppercase">
                <th className=" px-6 py-3">user</th>
                <th className=" px-6 py-3">Role</th>
                <th className=" px-6 py-3">Actions</th>
              </tr>

              {usersData.map((item, index) => (
                <tr
                  className={
                    index + 1 !== usersData.length
                      ? " border-b border-sGray-border"
                      : ""
                  }
                >
                  <td className=" px-6 py-3 flex items-center">
                    <img
                      src={item?.photo ?? "/assets/img/avatar.jpg"}
                      alt="avatar"
                      className="h-10 w-10 rounded-full  mr-4"
                    />
                    <div>
                      <div className="text-sm font-medium text-white">
                        {item?.firstName}&nbsp;{item?.lastName}
                      </div>
                      <div className="text-sm font-normal text-gray-500">
                        {item?.email}
                      </div>
                    </div>
                  </td>
                  <td className=" px-6 py-3 text-sm font-normal text-gray-500">
                    {role[item?.role + 1]}
                  </td>
                  <td className=" px-6 py-3">
                    <div className=" flex">
                      <div
                        className="text-sm font-medium text-red-500 mr-4	 h-full  cursor-pointer"
                        onClick={() => {
                          const token = getCookie("access_token");
                          const id = item.id;
                          deleteUser({
                            token,
                            id,
                          }).then(async (r) => {
                            if (r.ok) {
                              setShowToast({
                                show: true,
                                message: "User successfully revoked",
                                type: "succes",
                              });
                            } else {
                              const { message } = await r.json();

                              setShowToast({
                                show: true,
                                message: Array.isArray(message)
                                  ? message.join("\n")
                                  : message,
                                type: "danger",
                              });
                            }
                          });
                        }}
                      >
                        Revoke access
                      </div>
                      <div
                        className="text-sm font-medium text-blue-500 mr-4  h-full cursor-pointer"
                        onClick={() => {
                          setShowModal(true);
                          setPinCode({
                            ...pinCode,
                            id: item.id,
                          });
                        }}
                      >
                        Change Pin
                      </div>
                      <ChangeRole id={item.id} />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>
      <Modal
        title="Change Pin"
        visible={showModal}
        onClose={() => setShowModal((prev) => !prev)}
        onValid={() => {
          const token = getCookie("access_token");
          patchPin({
            token,
            body: pinCode,
          }).then(async (r) => {
            if (r.ok) {
              const { data } = await r.json();
              setShowToast({
                show: true,
                message: "Pin successfully changed",
                type: "succes",
              });
              setShowModal((prev) => !prev);
            } else {
              const { error, message } = await r.json();

              setShowToast({
                show: true,
                message: Array.isArray(message) ? message.join("\n") : message,
                type: "danger",
              });
              console.log("patchPin error", message);
            }
          });
        }}
      >
        <Input
          label="PIN"
          placeholder="PIN"
          className="mb-5"
          onChange={(event) =>
            setPinCode({
              ...pinCode,
              newPin: event.target.value,
            })
          }
        />
      </Modal>
      <Drawer
        title="Create new user"
        visible={showDrawer}
        onClose={() => setShowDrawer((prev) => !prev)}
        onValid={() => {
          if (email && firstName && lastName) {
            const token = getCookie("access_token");

            const body = {invitedUsers:[
              {
                email: email,
                organizationId: hub?.organization,
                hubId: hub?.value,
                password: password,
                role: 3,
                firstName: firstName,
                lastname: lastName,
              },
            ]};
            postUser({
              token,
              body,
            }).then(async (r) => {
              if (r.ok) {
                // const { data } = await r.json();
                setShowToast({
                  show: true,
                  message: "User successfully created",
                  type: "succes",
                });
                setShowDrawer((prev) => !prev);
              } else {
                const { message } = await r.json();
                setShowToast({
                  show: true,
                  message: Array.isArray(message)
                    ? message.join("\n")
                    : message,
                  type: "danger",
                });
              }
            });
          } else {
            setShowToast({
              show: true,
              message: "Email or First Name or Last Name is empty",
              type: "warning",
            });
          }
        }}
      >
        <Input
          label="Email"
          className=""
          placeholder="Email"
          onChange={(event) => setEmail(event.target.value)}
        />

        <Input
          label="Password"
          className="mt-5"
          placeholder="Password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <Input
          label="First Name"
          className="mt-5"
          placeholder="First Name"
          onChange={(event) => setFirstName(event.target.value)}
        />
        <Input
          label="Last Name"
          className="mt-5"
          placeholder="Last Name"
          onChange={(event) => setLastName(event.target.value)}
        />
        <Dropdown label="Hub" fullwidth options={allHubs} onSelect={setHub}>
          <Input
            className="cursor-pointer mt-5"
            label="Hub"
            placeholder={hub?.text || "Hub"}
            icon="/assets/icon/arrow-down-white.svg"
            disabled
          />
        </Dropdown>
      </Drawer>
      {showToast.show ? (
        <Toast message={showToast.message} type={showToast.type} />
      ) : null}
    </div>
  );
}
