import React from "react";
import PropTypes from "prop-types";

export default function Button({ children, className, ...props }) {
  return (
    <div
      {...props}
      className={`bg-sGreen text-sBlack-txt rounded-md py-2 text-center cursor-pointer ${
        className || ""
      }`}
    >
      {children}
    </div>
  );
}
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
