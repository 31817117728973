// import { Route, Redirect } from "react-router-dom";

// function PublicRoute({ children, isAuthenticated, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         !isAuthenticated ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: "/dashboard",
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// }

// export default PublicRoute;

import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({
  component: Component,
  isLogin,
  restricted,
  ...rest
}) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isLogin && restricted ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
