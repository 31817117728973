import { URLS } from "./constants";

export const login = (body) =>
  fetch(URLS.LOGIN, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

export const promoteUser = ({ token, id, body }) =>
  fetch(URLS.PROMOTE_USER + "/" + id + "/promote", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

export const uploadUser = (body) =>
  fetch(URLS.UPLOAD_USER, {
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: body,
  });

export const deleteUser = ({ token, id }) =>
  fetch(URLS.DELETE_USER + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

export const getUserById = ({ token, id }) =>
  fetch(URLS.GET_USER_BY_ID + "/" + id, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const getAllTools = ({ token }) =>
  fetch(URLS.GET_ALL_TOOLS, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllSpaces = ({ token }) =>
  fetch(URLS.GET_ALL_SPACES, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllHubs = ({ token }) =>
  fetch(URLS.GET_ALL_HUBS, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllToolsTemplates = ({ token }) =>
  fetch(URLS.GET_ALL_TOOLS_TEMPLATES, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getAllUsers = ({ token }) =>
  fetch(URLS.GET_ALL_USERS, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getOldBookings = ({ token, toolId, spaceId, date }) => {
  const querydate = date ? date + "?" : "?";
  const querySpaceId = spaceId ? "&spaceId=" + spaceId : "";
  const queryToolId = toolId ? "&toolId=" + toolId : "";

  return fetch(
    URLS.GET_OLD_BOOKINGS +
      querydate +
      "status=finished" +
      querySpaceId +
      queryToolId,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const getAllTrainings = ({ token }) =>
  fetch(URLS.GET_ALL_TRAININGS, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const getTrainings = ({ token }) =>
  fetch(URLS.GET_TRAININGS, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getBookingsByDate = ({ token, date }) =>
  fetch(URLS.GET_BOOKINGS_BY_DATE + "/" + date, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const postBooking = ({ token, body, iscontrolled }) =>
  fetch(URLS.POST_BOOKING + "?iscontrolled=" + iscontrolled, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

export const postTool = ({ token, body }) =>
  fetch(URLS.POST_TOOL, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

export const postUser = ({ token, body }) =>
  fetch(URLS.INVITE_USER, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
export const postTemplate = ({ token, body }) =>
  fetch(URLS.POST_TEMPLATE, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

export const patchTrainingStatus = ({ token, id, body }) =>
  fetch(URLS.PATCH_ID_TRAININGS + "/" + id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

export const patchPin = ({ token, body }) =>
  fetch(URLS.PATCH_PIN, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

export const restPass = ({ token, body }) =>
  fetch(URLS.RESET_PASS, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
export const editProfil = ({ token, body, id }) =>
  fetch(URLS.EDIT_PROFIL + id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
