import React from "react";
import moment from "moment";
import {
  Input,
  Button,
  Dropdown,
  TimePicker,
  Toast,
} from "../../components";
import "../../styles/Calendar.scss";
import "react-datepicker/dist/react-datepicker.css";
import { getAllTools, postBooking } from "../../utils/api";
import getCookie from "../../utils/getcookie";
import {
  UserStateContext,
} from "../../contexts/userContext";
import { useCookies } from "react-cookie";

export default function NewBooking() {
  // eslint-disable-next-line no-unused-vars
  const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);

  const { user } = React.useContext(UserStateContext);
  console.log("test user ", user);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showToast, setShowToast] = React.useState({
    show: false,
    message: "",
    type: "",
  });
  React.useEffect(() => {
    if (showToast.show) {
      const timer = setTimeout(() => {
        setShowToast({
          show: false,
          message: "",
          type: "",
        });
      }, 3000); // delay

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showToast]);
  const [allTools, setAllTools] = React.useState([]);

  const [availbleTools, setAvailbleTools] = React.useState([]);

  const [selectedTools, setSelectedTools] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [projectName, setProjectName] = React.useState("");
  const [projectDesc, setProjectDesc] = React.useState("");

  React.useEffect(() => {
    if (!showDrawer) {
      const token = getCookie("access_token");
      getAllTools({
        token,
      }).then(async (r) => {
        if (r.ok) {
          const { data } = await r.json();
          const toolsoptions = data.map((item) => ({
            text: item.title,
            value: item.id,
            controller: item.controller,
          }));
          setAllTools(toolsoptions);
          const tools = data.map((item) => ({
            resourceId: item?.id,
            resourceTitle: item?.title,
          }));
          setAvailbleTools(tools);
        } else {
          console.log("getAllTools error");
          if (r.status == 401) {
            removeCookie("access_token", "");
          }
        }
      });
    }
  }, [showDrawer]);
  return (
    <div>
      <div className="container  my-14">
        <div className="text-2xl font-medium text-white">New booking</div>

        <div className="mt-10">
          <Dropdown
            label="Select Tool"
            fullwidth
            options={allTools}
            onSelect={setSelectedTools}
          >
            <Input
              className="cursor-pointer"
              label="Tool"
              placeholder={selectedTools?.text}
              icon="/assets/icon/arrow-down-white.svg"
              disabled
            />
          </Dropdown>

          <div className="mt-5 flex">
            <TimePicker
              label="Start time"
              className="mr-1"
              onChangeTime={(value) =>
                setStartTime(moment.duration(value, "hh:mm a").as("seconds"))
              }
            />
            <TimePicker
              label="End time"
              className="ml-1"
              onChangeTime={(value) =>
                setEndTime(moment.duration(value, "hh:mm a").as("seconds"))
              }
            />
          </div>
          <Input
            label="Project name"
            className="mt-5"
            placeholder="Device ID"
            onChange={(event) => setProjectName(event.target.value)}
          />
          <label
            htmlFor="project-desc"
            className="block text-sm font-medium text-white mt-5"
          >
            Project description
          </label>
          <textarea
            onChange={(event) => setProjectDesc(event.target.value)}
            placeholder="Describe your project here"
            cols="30"
            rows="6"
            name="project-desc"
            className="text-gray-500 bg-transparent px-3 py-2 mt-1 focus:ring-indigo-500 focus:border-sGray-border focus-visible:border-sGray-border block w-full shadow-sm sm:text-sm border-sGray-border border rounded-md"
          />
        </div>
        <Button
          className=" px-4 py-2 w-full text-center mt-5 mb-0"
          onClick={() => {
            if (startTime && endTime && projectName && projectDesc) {
              const token = getCookie("access_token");

              const body = {
                timeSlot: {
                  from: startTime,
                  to: endTime,
                },
                projectName: projectName,
                projectDesc: projectDesc,
                bookingDate: moment().format("YYYY-MM-DD"),
                status: "pending",
                ownerId: user.id,
                toolId: selectedTools.value,
                buddyEmail: user.email,
              };
              const iscontrolledstatus = selectedTools.controller !== null;
              postBooking({
                token,
                body,
                iscontrolled: iscontrolledstatus,
              }).then(async (r) => {
                if (r.ok) {
                  // const { data } = await r.json();
                  setShowToast({
                    show: true,
                    message: "Booking successfully created",
                    type: "succes",
                  });
                  setShowDrawer((prev) => !prev);
                } else {
                  const { message } = await r.json();

                  setShowToast({
                    show: true,
                    message: Array.isArray(message)
                      ? message.join("\n")
                      : message,
                    type: "danger",
                  });
                }
              });
            } else {
              setShowToast({
                show: true,
                message: "Project name or Project description data is missing",
                type: "warning",
              });
            }
          }}
        >
          Confirm
        </Button>
        {showToast.show ? (
          <Toast message={showToast.message} type={showToast.type} />
        ) : null}
      </div>
    </div>
  );
}
