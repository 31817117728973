import React from "react";
import {
  Drawer,
  CardTool,
  Button,
  Input,
  Checkbox,
  Dropdown,
  Loader,
  Toast,
} from "../../components";
import {
  getAllTools,
  getAllSpaces,
  getAllToolsTemplates,
  postTool,
  postTemplate,
} from "../../utils/api";
import getCookie from "../../utils/getcookie";
import {
  UserStateContext,
} from "../../contexts/userContext";
import { useCookies } from "react-cookie";

const Tools = ({ data }) => (
  <div>
    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 mt-14">
      {data.map((item, index) => (
        <CardTool data={item} key={index} />
      ))}
    </div>

    <div className="text-2xl font-medium text-white mt-20">Other tools</div>

    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 mt-14">
      <CardTool />
    </div>
  </div>
);
const Templates = ({ data }) => (
  <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 mt-14">
    {data.map((item, index) => (
      <CardTool data={item} Template key={index} />
    ))}
  </div>
);

export default function ManageTools() {
  const [isLoading, setIsLoading] = React.useState(true);

  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showDrawerTemp, setShowDrawerTemp] = React.useState(false);
  const [tab, setTab] = React.useState(true);
  const [tools, setTools] = React.useState([]);
  const [templates, setTemplates] = React.useState([]);
  const [spaces, setSpaces] = React.useState([]);
  // --- new template --- //
  const [selectedSpace, setSelectedSpace] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState({});
  const [toolName, setToolName] = React.useState("");
  // --- new template --- //
  // --- new template --- //
  const [showToast, setShowToast] = React.useState({
    show: false,
    message: "",
    type: "",
  });
  React.useEffect(() => {
    if (showToast.show) {
      const timer = setTimeout(() => {
        setShowToast({
          show: false,
          message: "",
          type: "",
        });
      }, 3000); // delay

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showToast]);
    const [cookies, removeCookie] = useCookies(["access_token", "_idUser"]);


  const [templateName, setTemplateName] = React.useState("");
  const [templateDesc, setTemplateDesc] = React.useState("");
  const [templateMinTime, setTemplateMinTime] = React.useState("");
  const [templateMaxTime, setTemplateMaxTime] = React.useState("");
  const [buddyMode, setBuddyMode] = React.useState(false);
  const [availableInWorkingHours, setAvailableInWorkingHours] =
    React.useState(false);
  const [requiresTraining, setRequiresTraining] = React.useState(false);
  const [sound, setSound] = React.useState(false);
  const [inputFiles, setInputFiles] = React.useState(false);
  const [toolsToAdd, setToolsToAdd] = React.useState([]);

  // *** new template *** //
  const { user } = React.useContext(UserStateContext);

  React.useEffect(() => {
    const token = getCookie("access_token");

    getAllTools({
      token,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        setIsLoading(false);
        setTools(data);
      } else {
        console.log("getAllTools error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
    getAllToolsTemplates({
      token,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        setIsLoading(false);
        setTemplates(data);
      } else {
        console.log("getAllToolsTemplates error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
    getAllSpaces({
      token,
    }).then(async (r) => {
      if (r.ok) {
        const { data } = await r.json();
        const spacesOptions = data.map((item) => ({
          text: item.title,
          value: item.id,
          controller: item.controller,
        }));
        setSpaces(spacesOptions);
      } else {
        console.log("getAllSpaces error");
        if (r.status == 401) {
          removeCookie("access_token", "");
        }
      }
    });
  }, []);

  return (
    <div>
      <div className="container my-14">
        <div className="flex justify-between flex-wrap ">
          <div className="text-2xl font-medium text-white md:order-1 order-2">
            {tab ? "Controlled tools" : "List of templates"}
          </div>
          <div className="flex md:order-2 order-1 w-full md:w-auto md:mb-0 mb-10">
            <div
              className={`rounded-l-lg bg-sBlack-txt w-44 cursor-pointe border-b-2 h-10 flex items-center justify-center ${
                tab
                  ? "border-sGreen text-sGreen"
                  : "border-sGray-border text-sGray-light border-r"
              }`}
              onClick={() => setTab(true)}
            >
              Tools
            </div>
            <div
              onClick={() => {
                if (templates.length === 0) {
                  setIsLoading(true);
                }
                setTab(false);
              }}
              className={`cursor-pointer  rounded-r-lg bg-sBlack-txt w-44   border-b-2 h-10 flex items-center justify-center ${
                tab
                  ? "border-sGray-border text-sGray-light border-l "
                  : "border-sGreen text-sGreen"
              }`}
            >
              Templates
            </div>
          </div>
          <div className="order-3 w-auto">
            {tab ? (
              <Button
                className="flex md:px-4 md:py-2 md:w-auto w-10 h-10  justify-center items-center"
                onClick={() => setShowDrawer(true)}
              >
                <img
                  src="/assets/icon/plus.svg"
                  alt="Create new tool"
                  className="md:pr-3"
                />
                <span className="text-sm font-medium w-full hidden md:block">
                  Create new tool
                </span>
              </Button>
            ) : (
              <Button
                className="flex md:px-4 md:py-2 md:w-auto w-10 h-10  justify-center items-center"
                onClick={() => setShowDrawerTemp(true)}
              >
                <img
                  src="/assets/icon/plus.svg"
                  alt="Create new tool"
                  className="md:pr-3"
                />
                <span className="text-sm font-medium w-full  hidden md:block">
                  Create new template
                </span>
              </Button>
            )}
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : tab ? (
          <Tools data={tools} />
        ) : (
          <Templates data={templates} />
        )}
      </div>
      <Drawer
        title="Create a new tool"
        visible={showDrawer}
        onClose={() => setShowDrawer((prev) => !prev)}
        onValid={() => {
          if (toolName) {
            const token = getCookie("access_token");

            const body = [
              {
                title: toolName,
                templateId: selectedTemplate?.value,
                controllerId: "65d9ceee-9887-4eb8-be38-c6da0417cf49",
              },
            ];

            postTool({
              token,
              body,
            }).then(async (r) => {
              if (r.ok) {
                // const { data } = await r.json();
                setShowToast({
                  show: true,
                  message: "Tool successfully created",
                  type: "succes",
                });
                setShowDrawer((prev) => !prev);
              } else {
                const { message } = await r.json();
                setShowToast({
                  show: true,
                  message: Array.isArray(message)
                    ? message.join("\n")
                    : message,
                  type: "danger",
                });
              }
            });
          } else {
            setShowToast({
              show: true,
              message: "Name or Space or Template is empty",
              type: "warning",
            });
          }
        }}
      >
        <Input
          label="Name"
          placeholder="Tool name"
          onChange={(event) => setToolName(event.target.value)}
        />
        {/* TODO: fix this please
        <Input label="Device ID" className="mt-5" placeholder="Device ID" /> 
        
        */}
        <Dropdown
          label="Select Tool"
          fullwidth
          options={spaces}
          onSelect={setSelectedSpace}
        >
          <Input
            className="cursor-pointer mt-5"
            label="Space"
            placeholder={selectedSpace?.text ?? "Select a space"}
            icon="/assets/icon/arrow-down-white.svg"
            disabled
          />
        </Dropdown>

        <Dropdown
          label="Select Tool"
          fullwidth
          options={templates.map((item) => ({
            text: item.title,
            value: item.id,
            controller: item.controller,
          }))}
          onSelect={setSelectedTemplate}
        >
          <Input
            className="cursor-pointer mt-5"
            label="Template"
            placeholder={selectedTemplate?.text ?? "Select a template"}
            icon="/assets/icon/arrow-down-white.svg"
            disabled
          />
        </Dropdown>
      </Drawer>
      <Drawer
        title="Create a new template"
        visible={showDrawerTemp}
        onClose={() => setShowDrawerTemp((prev) => !prev)}
        onValid={() => {
          if (
            templateName &&
            templateDesc &&
            templateMinTime &&
            templateMaxTime
          ) {
            const token = getCookie("access_token");

            const body = {
              title: templateName,
              description: templateDesc,
              minWorkTime: templateMinTime,
              maxWorkTime: templateMaxTime,
              buddyMode: buddyMode,
              availableInWorkingHours: availableInWorkingHours,
              requiresTraining: requiresTraining,
              sound: sound,
              organizationId: user.organizationId,
              tools: toolsToAdd,
              uncontrolledTools: [],
            };

            postTemplate({
              token,
              body,
            }).then(async (r) => {
              if (r.ok) {
                const { data } = await r.json();
                setShowToast({
                  show: true,
                  message: "Template successfully created",
                  type: "succes",
                });
                setShowDrawerTemp((prev) => !prev);
              } else {
                const { message } = await r.json();
                setShowToast({
                  show: true,
                  message: Array.isArray(message)
                    ? message.join("\n")
                    : message,
                  type: "danger",
                });
              }
            });
          } else {
            setShowToast({
              show: true,
              message: "Title or Description or Working hours is empty",
              type: "warning",
            });
          }
        }}
      >
        <div className="text-sGreen text-lg mb-5 font-medium">Information</div>
        <Input
          label="Name"
          placeholder="Template name"
          onChange={(event) => setTemplateName(event.target.value)}
        />
        <label
          htmlFor="project-desc"
          className="block text-sm font-medium text-white mt-5"
        >
          Description
        </label>
        <textarea
          onChange={(event) => setTemplateDesc(event.target.value)}
          placeholder="Template description"
          cols="30"
          rows="6"
          name="project-desc"
          className="text-gray-500 bg-transparent px-3 py-2 mt-1 focus:ring-indigo-500 focus:border-sGray-border focus-visible:border-sGray-border block w-full shadow-sm sm:text-sm border-sGray-border border rounded-md"
        />
        <label
          htmlFor="project-desc"
          className="block text-sm font-medium text-white mt-5"
        >
          Working hours
        </label>
        <div className=" flex">
          <Input
            placeholder="Min. hours"
            type="number"
            className="mr-1"
            onChange={(event) => setTemplateMinTime(event.target.value)}
          />

          <Input
            placeholder="Max. hours"
            type="number"
            className="ml-1"
            onChange={(event) => setTemplateMaxTime(event.target.value)}
          />
        </div>
        <div className="text-sGreen text-lg mb-5 font-medium mt-10">
          Technical details
        </div>
        <Checkbox
          label="Available in Buddy Mode"
          desc="Tools from this template can be shared"
          onChange={() => setBuddyMode((prev) => !prev)}
        />
        <Checkbox
          label="Available in working hours"
          desc="Tools from this template are only available during working hours"
          className="mt-5"
          onChange={() => setAvailableInWorkingHours((prev) => !prev)}
        />
        <Checkbox
          label="Requires training"
          desc="Tools from this template require training to be used"
          className="mt-5"
          onChange={() => setRequiresTraining((prev) => !prev)}
        />
        <Checkbox
          label="Requires input files"
          desc="Tools from this template require inpute files in order to be used"
          className="mt-5"
          onChange={() => setInputFiles((prev) => !prev)}
        />
        <Checkbox
          label="Produces sound"
          desc="Tools from this template produces sound while in use"
          className="mt-5"
          onChange={() => setSound((prev) => !prev)}
        />
        <div className="text-sGreen text-lg mb-5 font-medium mt-10">
          Technical details
        </div>
        <div className="flex">
          <div className="w-1/2">
            <div className="text-sBlue text-lg font-light mt-5 mb-4 ">
              Controlled tools
            </div>
            {tools.map((item, index) => (
              <Checkbox
                label={item.title}
                className={index !== 0 ? "mt-4" : null}
                onChange={() => {
                  const arrayNew = toolsToAdd;
                  const index = arrayNew.indexOf(item.id);
                  if (index === -1) {
                    arrayNew.push(item.id);
                  } else {
                    arrayNew.splice(index, 1);
                  }
                  setToolsToAdd(arrayNew);
                }}
              />
            ))}
          </div>
          <div className="w-1/2">
            <div className="text-sBlue text-lg font-light mt-5 mb-4 ">
              Uncontrolled tools
            </div>
            <Checkbox label="LATHE06" />
          </div>
        </div>
      </Drawer>
      {showToast.show ? (
        <Toast message={showToast.message} type={showToast.type} />
      ) : null}
    </div>
  );
}
