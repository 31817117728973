import React from "react";
import Login from "../pages/Login";
import CalendarPage from "../pages/Dashboard/Calendar";
import Training from "../pages/Dashboard/Training";
import Users from "../pages/Dashboard/Users";
import Profile from "../pages/Dashboard/Profile";
import ManageTools from "../pages/Dashboard/ManageTools";
import History from "../pages/Dashboard/History";
import NewBooking from "../pages/Dashboard/NewBooking";

import { BrowserRouter, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoute";
import { useCookies } from "react-cookie";

import { Navbar } from "../components";

function Routes() {
  const [cookies] = useCookies(["access_token", "_idUser"]);

  const isAuthenticated = cookies["access_token"] ? true : false;

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute
          restricted={true}
          component={Login}
          path="/"
          exact
          isLogin={isAuthenticated}
        />
        <PublicRoute
          restricted={true}
          component={Login}
          path="/login"
          exact
          isLogin={isAuthenticated}
        />

        <div className="flex flex-col h-screen ">
          <Navbar />
          <div className="overflow-auto md:pr-2 bg-sBlack w-full h-full relative flex-1">
            <PrivateRoute
              component={CalendarPage}
              path="/dashboard"
              exact
              isLogin={isAuthenticated}
            />
            <PrivateRoute
              component={CalendarPage}
              path="/dashboard/calendar"
              exact
              isLogin={isAuthenticated}
            />
            <PrivateRoute
              component={Users}
              path="/dashboard/users"
              exact
              isLogin={isAuthenticated}
            />
            <PrivateRoute
              component={Profile}
              path="/dashboard/profile"
              exact
              isLogin={isAuthenticated}
            />
            <PrivateRoute
              component={History}
              path="/dashboard/history"
              exact
              isLogin={isAuthenticated}
            />
            <PrivateRoute
              component={ManageTools}
              path="/dashboard/manage-tools"
              exact
              isLogin={isAuthenticated}
            />

            <PrivateRoute
              component={Training}
              path="/dashboard/training"
              exact
              isLogin={isAuthenticated}
            />
            <PrivateRoute
              component={NewBooking}
              path="/dashboard/new-booking"
              exact
              isLogin={isAuthenticated}
            />
          </div>
        </div>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
