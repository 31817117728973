const baseUrl = "http://api.synxusa.com/api/v1";
// const baseUrl = `https://api-synxus.herokuapp.com/api/v1`;
console.log(baseUrl);
export const URLS = {
  LOGIN: `${baseUrl}/auth/sign-in`,
  RESET_PASS: `${baseUrl}/auth/reset-password`,

  GET_USER_BY_ID: `${baseUrl}/users`,
  PROMOTE_USER: `${baseUrl}/users`,
  DELETE_USER: `${baseUrl}/users/`,
  UPLOAD_USER: `${baseUrl}/users/csv`,
  INVITE_USER: `${baseUrl}/users`,
  EDIT_PROFIL: `${baseUrl}/users/`,
  GET_ALL_TOOLS: `${baseUrl}/tools`,
  POST_TOOL: `${baseUrl}/tools`,
  POST_TEMPLATE: `${baseUrl}/tools/template`,
  GET_OLD_BOOKINGS: `${baseUrl}/bookings/`,

  GET_ALL_SPACES: `${baseUrl}/spaces`,

  GET_ALL_HUBS: `${baseUrl}/hubs`,

  GET_ALL_TOOLS_TEMPLATES: `${baseUrl}/tools/templates`,
  GET_ALL_USERS: `${baseUrl}/users`,
  GET_ALL_TRAININGS: `${baseUrl}/scores`,
  PATCH_ID_TRAININGS: `${baseUrl}/scores/status`,
  PATCH_PIN: `${baseUrl}/users/rfid/pin`,

  GET_BOOKINGS_BY_DATE: `${baseUrl}/bookings`,
  POST_BOOKING: `${baseUrl}/bookings`,

  GET_TRAININGS: `${baseUrl}/trainings/uncontrolled_tool`,
};
